<template>
  <div class="forgot-password">
    <login-panel :isLogin='false'></login-panel>
    <login-footer></login-footer>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import LoginPanel from '../login-panel'
import LoginFooter from '../login-footer'

export default defineComponent({
  props: {

  },
  components: {
    LoginPanel,
    LoginFooter
  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>




